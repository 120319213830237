/* Global CSS variables */
:root {
	--spacing-company: 3rem;
	--font-weight: 400;
	--border-radius: 0;
}

nav {
	position: fixed;
	/* background-color: #11191f; */
}

/* Typography */
h2,
h3,
hgroup > :last-child {
	font-weight: 200;
}

small {
	color: var(--muted-color);
}

/* Header */
.hero {
	background-color: #394046;
	/* background-image: url("img/sascha-eremin-DNQ-M93tHmA-unsplash-3000x1000.jpg"); */
	background-position: center;
	background-size: cover;
}

header h1,
header p {
	text-align: center;
	font-size: 46px;
}

header {
	padding: var(--spacing-company) 0;
}

header hgroup > :last-child {
	color: var(--h3-color);
}

header hgroup {
	margin-bottom: var(--spacing-company);
}

/* Nav */
summary[role="link"].contrast:is([aria-current], :hover, :active, :focus) {
	background-color: transparent;
	color: var(--contrast-hover);
}
